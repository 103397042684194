import React, { useEffect, useRef, useState } from 'react';
import { carouselimg } from "../../Helpers/ConsData";

export default function CarouselCaracteristicas({imgcarousel}) {
    return(
        <>
        
        <section className="">
        <div className="">
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators ">
        {imgcarousel.map((item, index) => (
            <li data-target="#carouselExampleIndicators" data-slide-to={index} className={`${index === 0 ? 'active' : ''}`}></li>
        ))}
        </ol>
        <div className="carousel-inner">
            {imgcarousel.map((item, index) => (
                <div 
                    className={`carousel-item ${index === 0 ? 'active' : ''}`} 
                    key={index}
                >
                    <a href={item.link}>
                        <img 
                            className="d-block w-100" 
                            src={item.srcimg} 
                            alt={`Slide ${index + 1}`} 
                        />
                        <div className="carousel-caption d-none d-md-block">
                            <h5>{item.titulo}</h5>
                            <p>{item.texto}</p>
                        </div>
                    </a>
                </div>
            ))}
        </div>
        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
        </a>
    </div>
    </div>
    </section>
        </>
    )
  };