import React, { useState, useRef, useEffect } from 'react';
import { BaseUrl, correo, llamada, menumodelos, whatsapp } from '../Helpers/ConsData';
import { logoBaic, logoSika } from '../Helpers/ConsData';

export default function Menu() {
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const navbarRef = useRef(null);
    const [navbarHeight, setNavbarHeight] = useState(0);
    let hoverTimeout;
    let hoverTimeout2;

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const handleMouseEnter = () => {
        setIsHovered2(false);

        clearTimeout(hoverTimeout); // Evita que desaparezca si el mouse vuelve rápidamente
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        // Da un pequeño tiempo de gracia para que el usuario pueda moverse al submenú
        hoverTimeout = setTimeout(() => setIsHovered(false), 200);
    };

    const handleMouseEnter2 = () => {
        setIsHovered(false);

        clearTimeout(hoverTimeout2); // Evita que desaparezca si el mouse vuelve rápidamente
        setIsHovered2(true);
    };

    const handleMouseLeave2 = () => {
        // Da un pequeño tiempo de gracia para que el usuario pueda moverse al submenú
        hoverTimeout2 = setTimeout(() => setIsHovered2(false), 200);
    };
    
    useEffect(() => {
        // Obtiene la altura del navbar una vez que el componente ha sido renderizado
        if (navbarRef.current) {
            setNavbarHeight(navbarRef.current.offsetHeight);
        }
    }, []);


    // Abre o cierra el menú responsive
    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
        setActiveSubMenu(null); // Reinicia el submenú activo
    };

    const [activeOption, setActiveOption] = useState(null); // "modelos" o una categoría
    const [activeCategory, setActiveCategory] = useState(null); // Categoría activa para títulos

    const toggleOption = (option) => {
        setActiveOption((prev) => (prev === option ? null : option));
        setActiveCategory(null); // Resetea la categoría seleccionada
    };

    const toggleCategory = (category) => {
        setActiveCategory((prev) => (prev === category ? null : category));
    };

    return (
        <>
            <nav ref={navbarRef} className="navbar navbar-expand-lg navbar-light fixed-top position-absolute d-none d-lg-flex " style={{backgroundColor: isHovered || isHovered2 ? "white" : ""}}>
            <div className="container-fluid">
                
                    <a className="" href="/">
                        <img src={logoBaic} alt="Logo" className='img-fluid logo-responsive'/>
                    </a>
                    
                    <div className="collapse navbar-collapse justify-content-center">
                        <ul className="navbar-nav">
                            <li className="nav-item" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                <a className="nav-link h5 m-3 ml-5" href="#">Modelos</a>
                            </li>
                            {/*<li className="nav-item" >
                                <a className="nav-link h5 m-3" href="#">Ubicaciones</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link h5 m-3" href="#">Respaldo</a>
                            </li>*/}
                            <li className="nav-item" onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2}>
                                <a className="nav-link h5 m-3" href={`${process.env.PUBLIC_URL}/contacto`}>Contacto</a>
                            </li>
                        </ul>
                    </div>
                    
                        <img src={logoSika} alt="Logo" className='img-fluid' style={{ height: "40px" }}/>
                    
                </div>
            </nav>

            {isHovered && (
                <div
                    className="subnavbar position-absolute d-none d-lg-block Poppins-ExtraBold"
                    style={{
                        top: `${navbarHeight}px`, // Posiciona justo debajo del navbar
                        left: 0,
                        right: 0,
                        zIndex: 1000
                    }}
                    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    <div className="container-fluid py-5">
            {menumodelos.map((section, index) => (
                <div key={index}>
                    <h3 className='mb-3'>{section.category}</h3>
                    <div className="row mb-4">
                        {section.items.map((item, idx) => (
                            <div className="col-md-2 text-center" key={idx}>
                                <a href={item.link} className='text-undecorated text-dark'>
                                    <img src={item.imgSrc} alt={item.title} className="img-fluid mb-2" />
                                    <h5 className='text-decoration-none'>{item.title}</h5>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
                </div>
            )}

            {isHovered2 && (
                <div
                    className="subnavbar position-absolute d-none d-lg-block Poppins-ExtraBold"
                    style={{
                        top: `${navbarHeight}px`, // Posiciona justo debajo del navbar
                        left: 0,
                        right: 0,
                        zIndex: 1000
                    }}
                    onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2}>
                    <div className="container-fluid mt-4">
                        {/* Primera fila */}
                        <div className="row mb-4 justify-content-center">
                            <div className="col-md-2 text-center">
                            <a href={llamada} className='text-undecorated text-menu'> 
                               <h5 className='text-decoration-none'>Llamanos</h5>
                            </a> 
                            </div>
                            <div className="col-md-2 text-center">
                                <a href={whatsapp} target="_blank" className='text-undecorated text-menu'>
                                    <h5>Whatsapp</h5>
                                </a>
                            </div>
                            <div className="col-md-2 text-center">
                            <a href={correo} className='text-undecorated text-menu'>
                                <h5>Correo</h5>
                            </a>
                            </div>
                           
                        </div>

                        
                    </div>
                </div>
            )}


<nav className="navbar navbar-light fixed-top d-lg-none bg-white pt-3">
    <div className="container d-flex justify-content-between align-items-center">
        {/* Logo alineado a la izquierda */}
        <a className="navbar-brand" href="#" style={{ flex: '1' }}>
            <img src={logoBaic} alt="Logo" className="img-fluid logo-mobile" />
        </a>
        
        {/* Botón de menú alineado a la derecha */}
        <button className="navbar-toggler" type="button" onClick={toggleMobileMenu} style={{ marginLeft: 'auto',
    width: '75px',        // Ajusta según el tamaño deseado
    height: '75px',
    fontSize: '1.5rem' }}>
            <span className="navbar-toggler-icon"></span>
        </button>
    </div>
    
    {isMobileMenuOpen && (
                <div className="mobile-menu">
                    <ul className="navbar-nav">
                        {/* Opción Modelos */}
                        <li className="nav-item">
                            <a
                                className="nav-link h5 m-3"
                                onClick={() => toggleOption('modelos')}
                                href="#"
                            >
                                Modelos
                            </a>
                            {activeOption === 'modelos' && (
                                <div className="sub-menu">
                                    {/* Muestra las categorías */}
                                    {menumodelos.map((menu, index) => (
                                        <div key={index}>
                                            <a
                                                className="dropdown-item"
                                                onClick={() =>
                                                    toggleCategory(menu.category)
                                                }
                                                href="#"
                                            >
                                                {menu.category}
                                            </a>
                                            {activeCategory === menu.category && (
                                                <div className="sub-sub-menu">
                                                    {/* Muestra los títulos de los elementos */}
                                                    {menu.items.map((item, idx) => (
                                                        <a
                                                            href={item.link}
                                                            className="dropdown-item"
                                                            key={idx}
                                                        >
                                                            {item.title}
                                                        </a>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </li>
                        {/* Otras opciones del menú
                        <li className="nav-item">
                            <a className="nav-link h5 m-3" href="#">
                                Ubicación
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link h5 m-3" href="#">
                                Respaldo
                            </a>
                        </li> */}
                        <li className="nav-item">
                            <a
                                className="nav-link h5 m-3"
                                href={`${process.env.PUBLIC_URL}/contacto`}
                            >
                                Contacto
                            </a>
                        </li>
                    </ul>
                </div>
            )}
</nav>




        </>
    );
}
