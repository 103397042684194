import { galeria3 } from "../../Helpers/ConsData";
export default function Galeria({imggaleria}){
    
        const columna1 = imggaleria.filter(imagen => imagen.orden === 'c1' && imagen.columnas === 1);
  const columna2 = imggaleria.filter(imagen => imagen.orden === 'c2' && imagen.columnas === 1);
  const columna3 = imggaleria.filter(imagen => imagen.orden === 'c3' && imagen.columnas === 1);
  const columna2y3 = imggaleria.filter(imagen => imagen.columnas === 2 && (imagen.orden === 'c2' || imagen.orden === 'c3'));

  const getGridPosition = (item) => {
    let row = 0;
    let col = 0;

    switch (item.orden) {
      case 'c1':
        col = 0;
        if (item.columnas === 1) {
          row = 0;
        } else {
          row = 0;
        }
        break;
      case 'c2':
        col = 1;
        if (item.columnas === 1) {
          row = 0;
        } else {
          row = 1;
        }
        break;
      case 'c3':
        col = 2;
        row = 0;
        break;
      default:
        break;
    }

    return { row, col };
  };


  return (
    <>
      <section className="py-5 Poppins-regular">
        
        <div className="container-fluid">
      <div className="row no-gutters">
        {imggaleria.map((item, index) => {
          const { row, col } = getGridPosition(item);
          let colClasses = 'col-md-4';
          let imageClasses = 'w-100 h-100 object-cover';

          if (item.columnas === 2) {
            colClasses = 'col-md-8';
          }

          if (item.height > 400) {
            imageClasses += ' h-100';
          } else {
            imageClasses += ' h-50';
          }

          return (
            <div
              key={index}
              className={`${colClasses} mb-0 gallery-item`}
              style={{
                gridRow: `${row + 1} / span ${item.columnas}`,
                gridColumn: `${col + 1} / span ${item.columnas}`
              }}
            >
                <div className="m-1 image-container">
              <a href={item.link} target="_blank" rel="noreferrer">
              <span className="image-title2">{item.titulo}</span>
                <img src={item.src} alt={item.titulo} className={`gallery-zoom ${imageClasses}`} />
              </a>
            </div>
            </div>
          );
        })}
      </div>
    </div>
      </section>
        </>
    )
}