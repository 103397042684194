import Banner from "../../pagina/Modelo/Banner";
import CarouselCaracteristicas from "../../pagina/Modelo/CarouselCaracteristicas";
import Galeria from "../../pagina/Modelo/Galeria";
import SectionForm from "../../pagina/SectionForm";
import { vehiculosmodelos } from "../../Helpers/ConsData";
import { useParams, Link } from 'react-router-dom';
import FeaturesSection from "../../pagina/Modelo/FeaturesSection";
import SectionInfo from "../../pagina/Modelo/SectionInfo";

export default function Modelo(){
    const { modelo } = useParams();

    const modelosFiltrados = vehiculosmodelos.find(linea => linea.vehiculo === modelo);

 

    return(
        <>
        <Banner banner={modelosFiltrados.banner}/>
        <SectionInfo modeloinfo={modelosFiltrados} />
        <Galeria imggaleria={modelosFiltrados.galeria}/>
        <CarouselCaracteristicas imgcarousel={modelosFiltrados.caracteristicas1}/>
        <FeaturesSection imgsection={modelosFiltrados.caracteristicas2} />
        <SectionForm vehiculo={modelosFiltrados.vehiculo}/>
        </>
    )
}