import { BaseUrl } from "../../Helpers/ConsData"
export default function Banner({banner}){
   
    
    return(
        <>
         <section className="banner-first">
  <img
    src={banner}
    alt="Banner"
    style={{
      width: '100%',
      height: '100%',
      objectFit: 'cover', // Cambia a "contain" si prefieres ver toda la imagen
      objectPosition: 'center',
    }}
  />
</section>

        </>
    )
}