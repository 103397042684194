import React, { useState, useEffect } from "react";
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { correo_receptor, urlApicorreo } from "../Helpers/ConsData";

export default function SectionForm({vehiculo}) {
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [submitStatus, setSubmitStatus] = useState({
    message: "",
    type: "",
    termsAccepted: false
  });

  const [formData, setFormData] = useState({
    nombre: "",
    telefono: "",
    correo: "",
    modelo: vehiculo || '',
    comentario: "",
    terminos: false,
  });

  const [formValidated, setFormValidated] = useState(false);

  useEffect(() => {
    let timer;
    if (submitStatus.message) {
      timer = setTimeout(() => {
        setSubmitStatus({ message: "", type: "", termsAccepted: false });
      }, 10000);
    }
    return () => clearTimeout(timer);
  }, [submitStatus.message]);

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? (checked ? "Sí" : "No") : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!recaptchaValue) {
      setSubmitStatus({
        message: "Por favor completa el reCAPTCHA",
        type: "error",
        termsAccepted: formData.terminos
      });
      return;
    }
    
    const form = e.target;
  
    if (form.checkValidity()) {
      try {
        const response = await axios.post(`${urlApicorreo}`, {
          receiver: correo_receptor,
          subject: `Cotización BAIC - Modelo ${formData.modelo}`,
          recaptchaToken: recaptchaValue,
          formData: formData
        });
        
        // Successful submission
        setSubmitStatus({
          message: "Formulario enviado con éxito",
          type: "success",
          termsAccepted: formData.terminos
        });

        // Reset form
        setFormData({
          nombre: "",
          telefono: "",
          correo: "",
          modelo: vehiculo || '',
          comentario: "",
          terminos: false,
        });
        setFormValidated(false);
        setRecaptchaValue(null);
  
      } catch (error) {
        // Error in submission
        setSubmitStatus({
          message: "Hubo un error al enviar el formulario. Por favor, inténtalo de nuevo.",
          type: "error",
          termsAccepted: formData.terminos
        });
      }
    }
  
    setFormValidated(true);
  };

  return (
    <section className="py-5 Poppins-Bold">
      <div className="container" id="Formulario_cotizar">
        <div className="row justify-content-center border py-3">
          <div className="col-md-8">
            <h2 className="text-center mb-4">Cotiza tu BAIC</h2>
            
          

            <form
              className={`needs-validation ${formValidated ? "was-validated" : ""}`}
              noValidate
              onSubmit={handleSubmit}
            >

              {/* Campo Nombre */}
              <div className="mb-3">
                <label htmlFor="nombre" className="form-label">
                  Nombre completo
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg Poppins-Regular"
                  id="nombre"
                  name="nombre"
                  placeholder="Ingresa tu nombre completo"
                  required
                  value={formData.nombre}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">Por favor, ingresa tu nombre completo.</div>
              </div>

              {/* Campo Teléfono */}
              <div className="mb-3">
                <label htmlFor="telefono" className="form-label">
                  Teléfono
                </label>
                <input
                  type="tel"
                  className="form-control form-control-lg Poppins-Regular"
                  id="telefono"
                  name="telefono"
                  placeholder="Ingresa tu número de teléfono"
                  required
                  value={formData.telefono}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">Por favor, ingresa un número de teléfono válido.</div>
              </div>

              {/* Campo Correo */}
              <div className="mb-3">
                <label htmlFor="correo" className="form-label">
                  Correo Electrónico
                </label>
                <input
                  type="email"
                  className="form-control form-control-lg Poppins-Regular"
                  id="correo"
                  name="correo"
                  placeholder="nombre@ejemplo.com"
                  required
                  value={formData.correo}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">Por favor, ingresa un correo electrónico válido.</div>
              </div>

              {/* Campo Modelo */}
              <div className="mb-3">
                <label htmlFor="modelo" className="form-label">
                  Modelo
                </label>
                <select
                  className="form-select form-select-lg Poppins-Regular"
                  id="modelo"
                  name="modelo"
                  required
                  value={formData.modelo}
                  onChange={handleChange}
                >
                  <option value="">Selecciona un modelo</option>

                  <option value="BJ30">BJ30</option>
                  <option value="X7">X7</option>
                  <option value="X35">X35</option>
                  <option value="X55">X55</option>
                </select>
                <div className="invalid-feedback">Por favor, selecciona un modelo.</div>
              </div>

              {/* Campo Comentario */}
              <div className="mb-3">
                <label htmlFor="comentario" className="form-label">
                  Comentario
                </label>
                <textarea
                  className="form-control form-control-lg Poppins-Regular"
                  id="comentario"
                  name="comentario"
                  placeholder="Deja un comentario (opcional)"
                  value={formData.comentario}
                  onChange={handleChange}
                ></textarea>
              </div>

              {/* Campo Checkbox */}
              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="terminos"
                  name="terminos"
                  required
                  checked={formData.terminos}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="terminos">
                  Acepto los términos y condiciones.
                </label>
                <div className="invalid-feedback">Debes aceptar los términos y condiciones.</div>
              </div>
              <div 
                        className="justify-content-center text-center aling-items-center py-3">
                        <ReCAPTCHA
                        sitekey="6Le42Y8qAAAAAPZaaIlCJByqTrjprWRt2RdkXssD"
                        onChange={handleRecaptchaChange}
                        // Add these optional props for more robust verification
                        theme="light"
                        size="normal"
                      /></div>
              {/* Botón Enviar */}
              <button className="btn btn-outline-dark btn-lg w-100" type="submit">
                Enviar
              </button>
              {submitStatus.message && (
                <div className={`alert ${
                  submitStatus.type === 'success' 
                    ? 'alert-success' 
                    : 'alert-danger'
                } text-center mt-3`}>
                  <h3>{submitStatus.message}</h3>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
