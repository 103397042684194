
import Carousel from "../../pagina/Carousel";
import Section3 from "../../pagina/Section3";
import SectionModelos from "../../pagina/SectionModelos";
import SectionGaleria1 from "../../pagina/SectionGaleria1";
import Sectionmap from "../../pagina/Sectionmap";
import SectionGaleria2 from "../../pagina/SectionGaleria2";
import SectionBoton from "../../pagina/SectionBoton";
import BotonesFlotantes from "../../pagina/BotonesFloteantes";
import SectionMarca from "../../pagina/SectionMarca";
import SectionForm from "../../pagina/SectionForm";
export default function Inicio(){
    return(
        <>
    <Carousel />
    {/*<SectionMarca />*/}
    <Sectionmap />
    <SectionModelos />
    <Section3 />
    {/*<SectionGaleria1 />
    <SectionGaleria2 />*/}
   {/** <SectionBoton /> */} 
    <SectionForm />
        </>
    )
}