import { useEffect, useState } from 'react';

export default function SectionModelos2({ colores }) {
    const [activeIndex, setActiveIndex] = useState(0); // Estado para manejar el índice activo del carrusel

    useEffect(() => {
        if (colores.length > 0) {
            setActiveIndex(0); // Reinicia el carrusel al primer elemento cuando cambie el array
        }
    }, [colores]); // Se activa cuando el array 'colores' cambia

    return (
        <>
            {/* Carrusel de imágenes */}
            <div id="carouselColorModelo" className="carousel slide" data-ride="carousel" data-interval="false">
  <div className="carousel-inner">
    {colores.map((color, index) => (
      <div key={index} className={`carousel-item ${index === activeIndex ? 'active' : ''}`}>
        {/* Imagen principal */}
        <div className="d-flex justify-content-center align-items-center">
          <img className="d-block img-fluid img-xl" src={color.imgColor} alt={`Color ${index + 1}`} />
        </div>
        {/* Logo y precio */}
        <div className="d-flex justify-content-center align-items-center mt-3 px-3">
          <img
            className="img-fluid img-logo"
            src={color.imgLogo}
            alt={`Logo ${index + 1}`}
            style={{ maxWidth: '20%', height: 'auto' }}
          />
          <div className="text-center mx-5">
  <h4 className="Poppins-Regular">Precio de lanzamiento:</h4>
  <h3 className="Poppins-Bold">
    <small className="text-muted Poppins-Regular">Desde</small> Q. {color.precio}
  </h3>
</div>
        </div>
      </div>
    ))}
  </div>
</div>



            {/* Fila de colores */}
            <div className='row justify-content-center'>
            {colores.map((color, index) => {
    const colorStyle = color.color.includes(',') 
        ? { 
          background: `linear-gradient(180deg, ${color.color.split(',')[0]} 50%, ${color.color.split(',')[1]} 50%)`, 
            height: '50px', 
            width: '50px',
            borderRadius: '50%' // Crea círculo perfecto
        } 
        : { 
            backgroundColor: color.color, 
            height: '50px', 
            width: '50px',
            borderRadius: '50px' 
        };

    return (
        <a 
            key={index} 
            className='cursor-active' 
            data-target="#carouselColorModelo" 
            data-slide-to={index} 
            onClick={() => setActiveIndex(index)} // Actualiza el índice activo al hacer clic
        >
            <div className='col-mb-1 m-3'>
                <div className="color-box border" style={colorStyle}></div>
            </div>
        </a>
    );
})}
            </div>
        </>
    );
}
