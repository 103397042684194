import { correo, llamada, whatsapp, correo_receptor, num_whatsapp, num_llamada } from "../../Helpers/ConsData";
import SectionForm from "../../pagina/SectionForm";

export default function Contacto(){
    return(
        <>
        <section className="mt-5 Poppins-Bold">
            <div className="container py-5">
  <div className="row text-center mt-5">
   
    <div className="col-md-4 mb-4">
      <div className="card p-4 shadow">
        <i className="fa fa-phone fa-3x text-primary mb-3"></i>
        <h6 className="mb-2">{num_llamada}</h6>
        <p className=" Poppins-Regular">Contáctanos por teléfono para cualquier consulta.</p>
        <a href={llamada} className="btn btn-primary">Llamar</a>
      </div>
    </div>
    
    <div className="col-md-4 mb-4">
      <div className="card p-4 shadow">
        <i className="fa-brands fa-whatsapp fa-3x text-success mb-3"></i>
        <h6 className="mb-2">{num_whatsapp}</h6>
        <p className=" Poppins-Regular">Envíanos un mensaje y te responderemos pronto.</p>
        <a href={whatsapp} target="_blank" className="btn btn-success">Enviar WhatsApp</a>
      </div>
    </div>

    <div className="col-md-4 mb-4">
      <div className="card p-4 shadow">
        <i className="fa fa-envelope fa-3x text-danger mb-3"></i>
        <h6 className="mb-2 ">{correo_receptor}</h6>
        <p className=" Poppins-Regular">Envía tus preguntas o comentarios por correo electrónico.</p>
        <a href={correo} className="btn btn-danger">Enviar Correo</a>
      </div>
    </div>
  </div>
  </div>
</section>
        <SectionForm />
        </>
    )
}