

export default function SectionInfo({modeloinfo}){
    return(
        <>
        <section className="mt-5 Poppins-Regular"> 
        <div className="container">
        <div className="row border py-3">
            <div className="col-12 col-md-6 text-center">
                <img src={modeloinfo.logo} alt="Logo" className="img-fluid w-50" />
            </div>
            <div className="col-12 col-md-6 text-center">
            <h4 className="Poppins-Regular">Precio de lanzamiento:</h4>
  <h3 className="Poppins-Bold">
    <small className="text-muted Poppins-Regular">Desde</small> Q. {modeloinfo.precio}
  </h3>
            </div>
        </div>
    </div>
    </section>
        </>
    )
}