//export const BaseUrl = 'http://localhost:3000/design/assets/img/';
//export const BaseUrllogos = 'http://localhost:3000/design/assets/';
//export const urlApicorreo = 'http://localhost:41062/www/APIsWebSiteBAIC/send-mail';
export const BaseUrl = 'https://baic.gt/design/assets/img/';
export const BaseUrllogos = 'https://baic.gt/design/assets/';
export const urlApicorreo = 'https://baic.gt/APIsWebSiteBAIC/send-mail';
export const menumodelos = [
    {
        category: 'OFF-ROAD',
        items: [
            { title: 'BJ30', imgSrc: `${BaseUrl}Modelos/Off-road/BJ30/bj30mini.png`, link: `${process.env.PUBLIC_URL}/modelo/BJ30` },
            //{ title: 'BJ40', imgSrc: `${BaseUrl}Modelos/Off-road/BJ40/bj40plusmini.png`, link: `${process.env.PUBLIC_URL}/modelo/BJ40`  },
        ]
    },
    {
        category: 'SUV',
        items: [
            { title: 'X7', imgSrc: `${BaseUrl}Modelos/SUV/X7/x7newmini.png`, link: `${process.env.PUBLIC_URL}/modelo/X7` },
            { title: 'X55', imgSrc: `${BaseUrl}Modelos/SUV/X55/x55mini.png`, link: `${process.env.PUBLIC_URL}/modelo/X55` },
            { title: 'X35', imgSrc: `${BaseUrl}Modelos/SUV/X35/x35mini.png`, link: `${process.env.PUBLIC_URL}/modelo/X35` },
        ]
    }
];

//export const logoBaic =  `${BaseUrllogos}logoBAIC.png`;
//export const logoSika =  `${BaseUrllogos}sika_motors.webp`;
export const logoBaic =  `${BaseUrllogos}logoBAIC.png`;
export const logoSika =  `${BaseUrllogos}SikaMotors_Negro.png`;
export const logoSika2 =  `${BaseUrllogos}SikaMotors_Blanco.png`;


export const carouselimg = [
    { srcimg: `${BaseUrl}Carousel/00_BannerWeb.png`, link: '' },
    //{ srcimg: `${BaseUrl}Carousel/00_BannerWeb.png`, link: '' },
  ];

export const modelosoffroad = {
    modelos: [
        { imgSrc: `${BaseUrl}Modelos/Off-road/BJ30/bj30mini.png`, title: "BJ30", 
            colores: [
                { imgColor: `${BaseUrl}Modelos/Off-road/BJ30/bj30negro.png`, color: "#000000", imgLogo: `${BaseUrl}Modelos/Logos/BJ30_Negro.png`, precio: "249,990.00", },
                { imgColor: `${BaseUrl}Modelos/Off-road/BJ30/bj30gris.png`, color: "#808080", imgLogo: `${BaseUrl}Modelos/Logos/BJ30_Negro.png`, precio: "249,990.00", },
                { imgColor: `${BaseUrl}Modelos/Off-road/BJ30/bj30blanco.png`, color: "#f2f5f7", imgLogo: `${BaseUrl}Modelos/Logos/BJ30_Negro.png`, precio: "249,990.00", },
                { imgColor: `${BaseUrl}Modelos/Off-road/BJ30/bj30grisoscuro.png`, color: "#565a59", imgLogo: `${BaseUrl}Modelos/Logos/BJ30_Negro.png`, precio: "249,990.00", },
                { imgColor: `${BaseUrl}Modelos/Off-road/BJ30/bj30color1.png`, color: "#97b8a7", imgLogo: `${BaseUrl}Modelos/Logos/BJ30_Negro.png`, precio: "249,990.00", },
            ]
         },
        /*{ imgSrc: `${BaseUrl}Modelos/Off-road/BJ40/bj40plusmini.png`, title: "BJ40", 
            colores: [
                { imgColor: `${BaseUrl}Modelos/Off-road/BJ40/bj40rojo.png`, color: "#bc1d04", imgLogo: `${BaseUrl}Modelos/Logos/BJ30_Negro.png`, precio: "200,000.00", },
                { imgColor: `${BaseUrl}Modelos/Off-road/BJ40/bj40gris.png`, color: "#404544", imgLogo: `${BaseUrl}Modelos/Logos/BJ30_Negro.png`, precio: "200,000.00", },
                { imgColor: `${BaseUrl}Modelos/Off-road/BJ40/bj40blanco.png`, color: "#f5f5f5", imgLogo: `${BaseUrl}Modelos/Logos/BJ30_Negro.png`, precio: "200,000.00", },
                { imgColor: `${BaseUrl}Modelos/Off-road/BJ40/bj40negro.png`, color: "#080808", imgLogo: `${BaseUrl}Modelos/Logos/BJ30_Negro.png`, precio: "200,000.00", },
                { imgColor: `${BaseUrl}Modelos/Off-road/BJ40/bj40celeste.png`, color: "#bacbe2", imgLogo: `${BaseUrl}Modelos/Logos/BJ30_Negro.png`, precio: "200,000.00", },
                { imgColor: `${BaseUrl}Modelos/Off-road/BJ40/bj40azul.png`, color: "#193346", imgLogo: `${BaseUrl}Modelos/Logos/BJ30_Negro.png`, precio: "200,000.00", },
                { imgColor: `${BaseUrl}Modelos/Off-road/BJ40/bj40verde.png`, color: "#313d2a", imgLogo: `${BaseUrl}Modelos/Logos/BJ30_Negro.png`, precio: "200,000.00", },
                { imgColor: `${BaseUrl}Modelos/Off-road/BJ40/bj40beige.png`, color: "#5d6043", imgLogo: `${BaseUrl}Modelos/Logos/BJ30_Negro.png`, precio: "200,000.00", },
            ]
         }*/
    ]
};


export const modelossuv = {
    modelos: [
        { imgSrc: `${BaseUrl}Modelos/SUV/X7/x7newmini.png`, title: "X7", 
            colores: [
                { imgColor: `${BaseUrl}Modelos/SUV/X7/x7negro.png`, color: "#000000", imgLogo: `${BaseUrl}Modelos/Logos/X7_Negro.png`, precio: "234,990.00", },
                { imgColor: `${BaseUrl}Modelos/SUV/X7/x7gris.png`, color: "#d4d4d4", imgLogo: `${BaseUrl}Modelos/Logos/X7_Negro.png`, precio: "234,990.00", },
                { imgColor: `${BaseUrl}Modelos/SUV/X7/x7blanconegro.png`, color: "#000000,#f2f5f7", imgLogo: `${BaseUrl}Modelos/Logos/X7_Negro.png`, precio: "234,990.00", },
                { imgColor: `${BaseUrl}Modelos/SUV/X7/x7plateadonegro.png`, color: "#000000,#d4d4d4", imgLogo: `${BaseUrl}Modelos/Logos/X7_Negro.png`, precio: "234,990.00", },
                { imgColor: `${BaseUrl}Modelos/SUV/X7/x7grisnegro.png`, color: "#000000,#767676", imgLogo: `${BaseUrl}Modelos/Logos/X7_Negro.png`, precio: "234,990.00", },
                 
            ]
         },
        { imgSrc: `${BaseUrl}Modelos/SUV/X55/x55mini.png`, title: "X55", 
            colores: [
                { imgColor: `${BaseUrl}Modelos/SUV/X55/x55blanco.png`, color: "#f2f5f7", imgLogo: `${BaseUrl}Modelos/Logos/X55_negro.png`, precio: "199,990.00", },
                { imgColor: `${BaseUrl}Modelos/SUV/X55/x55gris.png`, color: "#424344", imgLogo: `${BaseUrl}Modelos/Logos/X55_negro.png`, precio: "199,990.00", },
                { imgColor: `${BaseUrl}Modelos/SUV/X55/x55grisclaro.png`, color: "#b1b3b4", imgLogo: `${BaseUrl}Modelos/Logos/X55_negro.png`, precio: "199,990.00", },
                { imgColor: `${BaseUrl}Modelos/SUV/X55/x55rojo.png`, color: "#000000,#d21313", imgLogo: `${BaseUrl}Modelos/Logos/X55_negro.png`, precio: "199,990.00", },
                { imgColor: `${BaseUrl}Modelos/SUV/X55/x55celeste.png`, color: "#000000,#29b7a4", imgLogo: `${BaseUrl}Modelos/Logos/X55_negro.png`, precio: "199,990.00", },
                
            ]
         },
         { imgSrc: `${BaseUrl}Modelos/SUV/X35/x35mini.png`, title: "X35",
            colores: [
                { imgColor: `${BaseUrl}Modelos/SUV/X35/x35blanco.png`, color: "#f2f5f7", imgLogo: `${BaseUrl}Modelos/Logos/X35_Negro.png`, precio: "164,990.00", },
                { imgColor: `${BaseUrl}Modelos/SUV/X35/x35rojo.png`, color: "#d21313", imgLogo: `${BaseUrl}Modelos/Logos/X35_Negro.png`, precio: "164,990.00", },
                { imgColor: `${BaseUrl}Modelos/SUV/X35/x35azul.png`, color: "#14174e", imgLogo: `${BaseUrl}Modelos/Logos/X35_Negro.png`, precio: "164,990.00", },
            ]
         }
    ]
};


export const galeria1 = [
    { src: `${BaseUrl}Galeria1/r1c1.jpg`, orden: 'c1', link: '', titulo: 'INVESTIGACIÓN Y DESARROLLO'  },
    { src: `${BaseUrl}Galeria1/r1c2.jpg`, orden: 'c2', link: '', titulo: 'VEHÍCULO TODOTERRENO'  },
    { src: `${BaseUrl}Galeria1/r2c1.jpg`, orden: 'c1', link: '', titulo: 'BAIC EN X'  },
    { src: `${BaseUrl}Galeria1/r2c2.jpg`, orden: 'c2', link: '', titulo: 'HISTORIA BAIC'  },
    { src: `${BaseUrl}Galeria1/r3c1.png`, orden: 'c1', link: '', titulo: ''  },
    
  ];

  export const galeria3 = [
    { src: `${BaseUrl}Galeria2/r1c1.png`, orden: 'c1', columnas: 1, link: '', titulo: '', height: 1062 },
    { src: `${BaseUrl}Galeria2/r1c2.jpg`, orden: 'c2', columnas: 1, link: '', titulo: 'ANTHONY LO - DESTACADO DISEÑADOR', height: 531 },
    { src: `${BaseUrl}Galeria2/r1c3.jpg`, orden: 'c3', columnas: 1, link: '', titulo: 'SINFORNIA ARTISTICA DE COLOR Y MATERIAL', height: 531 },
    { src: `${BaseUrl}Galeria2/r2c1.jpg`, orden: 'c1', columnas: 1, link: '', titulo: 'BAIC INTELIGENTE, "ESTABLE', height: 531 },
    { src: `${BaseUrl}Galeria2/r2c2c3.jpg`, orden: 'c2', columnas: 2, link: '', titulo: '', height: 531 },

  ];

  export const datafooter = [
    {columna: 1, title: 'Modelos',
        opciones: [
            {option: 1, url:`${process.env.PUBLIC_URL}/modelo/BJ30`, text:'BJ30'},
            //{option: 2, url:'', text:'BJ40'},
            {option: 3, url:`${process.env.PUBLIC_URL}/modelo/X7`, text:'X7'},
            {option: 3, url:`${process.env.PUBLIC_URL}/modelo/X35`, text:'X35'},
            {option: 3, url:`${process.env.PUBLIC_URL}/modelo/X55`, text:'X55'},
        ]
    },
    {columna: 2, title: 'Ubicaciones',
        opciones: [
            {option: 1, url:'', text:'Próximamente'},
        ]
    },
    {columna: 3, title: 'Respaldo',
        opciones: [
            {option: 1, url:'', text:'SikaMotors'},
        ]
    },
    {columna: 4, title: 'Contacto',
        opciones: [
            {option: 1, url:'${process.env.PUBLIC_URL}/contacto', text:'Contacta con nosotros'},
        ]
    }
  ]

  //MARCA
  export const marca1 = `${BaseUrl}Marca/marca1.png`;
  export const marca2 = `${BaseUrl}Marca/marca2.png`;
  export const marca3 = `${BaseUrl}Marca/marca3.png`;
  export const marca4 = `${BaseUrl}Marca/marca4.png`;


  //Modelos
  export const vehiculosmodelos = [
    {
        'id': 1,
        'vehiculo': 'BJ30',
        'banner': `${BaseUrl}Modelos/Banners/01BJ30.png`,
        'logo': `${BaseUrl}Modelos/Logos/BJ30_Negro.png`,
        'precio': "249,990.00",
        'galeria': [
            { 'src': `${BaseUrl}Modelos/Galeria/r1c1-bj30.png`, 'orden': 'c1', 'columnas': 1, 'link': '', 'titulo': 'Combinación de doble pantalla de 10,25 pulgadas + 14,6 pulgadas.', 'height': 1062 },
            { 'src': `${BaseUrl}Modelos/Galeria/r1c2-bj30.png`, 'orden': 'c2', 'columnas': 1, 'link': '', 'titulo': 'El selector de marchas electrónico adopta un diseño de propulsor de avión de combate.', 'height': 531 },
            { 'src': `${BaseUrl}Modelos/Galeria/r1c3-bj30.png`, 'orden': 'c3', 'columnas': 1, 'link': '', 'titulo': 'Posee una estructura frontal de absorción de energía de tres etapas.', 'height': 531 },
            { 'src': `${BaseUrl}Modelos/Galeria/r2c1-bj30.png`, 'orden': 'c1', 'columnas': 1, 'link': '', 'titulo': 'Los asientos se pueden reclinar para adaptarse a diferentes escenarios.', 'height': 531 },
            { 'src': `${BaseUrl}Modelos/Galeria/r2c2c3-bj30.png`, 'orden': 'c2', 'columnas': 2, 'link': '', 'titulo': 'La luz ambiental de 64 colores se mueve al ritmo de la melodía, llenando cada viaje de encanto tecnológico.', 'height': 531 },
        
          ],
        'caracteristicas1': [
            { 'srcimg': `${BaseUrl}Modelos/Fotos/bj30-1.png`, 'link': '', 'titulo': '', 'texto':''},
            { 'srcimg': `${BaseUrl}Modelos/Fotos/bj30-2.png`, 'link': '', 'titulo': '', 'texto':'' },
          ],
        'caracteristicas2': [
            {
                'imagen': `${BaseUrl}Modelos/Fotos/bj30-3.png`, 'titulo': 'Diseño trasero resistente', 'texto': 'La iluminación vertical sobre carril tiene forma de doble D, lo que la hace más reconocible.',
            },
            {
                'imagen': `${BaseUrl}Modelos/Fotos/bj30-4.png`, 'titulo': 'Faro', 'texto': 'El cinturón de luces diurnas de cinco orificios cuenta con un atractivo tecnológico y una utilidad práctica.',
            },
            {
                'imagen': `${BaseUrl}Modelos/Fotos/bj30-5.png`, 'titulo': 'Excelente rendimiento todoterreno', 'texto': 'El sólido chasis confiere al vehículo una excelente capacidad todoterreno. Ha sido sometido a rigurosas pruebas y verificaciones, lo que garantiza un rendimiento confiable del vehículo incluso en condiciones complejas de la carretera.',
            },
        ],
    },
    {
        'id': 2,
        'vehiculo': 'BJ40',
        'banner': `${BaseUrl}Modelos/Banners/01BJ30.png`,
        'logo': `${BaseUrl}Modelos/Logos/BJ30_Negro.png`,
        'precio': "200,000.00",
        'galeria': [
            { 'src': `${BaseUrl}Modelos/Galeria/r1c1-x7.png`, 'orden': 'c1', 'columnas': 1, 'link': '', 'titulo': 'Frenado autónomo de emergencia (AEB)', 'height': 1062 },
            { 'src': `${BaseUrl}Modelos/Galeria/r1c2-x7.png`, 'orden': 'c2', 'columnas': 1, 'link': '', 'titulo': 'Advertencia de colisión frontal (FCW)', 'height': 531 },
            { 'src': `${BaseUrl}Modelos/Galeria/r1c3-x7.png`, 'orden': 'c3', 'columnas': 1, 'link': '', 'titulo': 'Detección de punto ciego (BSD)', 'height': 531 },
            { 'src': `${BaseUrl}Modelos/Galeria/r2c1-x7.png`, 'orden': 'c1', 'columnas': 1, 'link': '', 'titulo': 'Advertencia de cambio de carril (LDW)', 'height': 531 },
            { 'src': `${BaseUrl}Modelos/Galeria/r2c2c3-x7.png`, 'orden': 'c2', 'columnas': 2, 'link': '', 'titulo': 'Control de crucero adaptativo integrado (IACC)', 'height': 531 },
        
          ],
        'caracteristicas1': [
            { 'srcimg': `${BaseUrl}Modelos/Fotos/x7-1.jpg`, 'link': '', 'titulo': '', 'texto':''},
            { 'srcimg': `${BaseUrl}Modelos/Fotos/x7-2.jpg`, 'link': '', 'titulo': '', 'texto':'' },
          ],
        'caracteristicas2': [
            {
                'imagen': `${BaseUrl}Modelos/Fotos/x7-3.jpg`, 'titulo': '', 'texto': '',
            },
            {
                'imagen': `${BaseUrl}Modelos/Fotos/x7-4.jpg`, 'titulo': '', 'texto': '',
            },
            {
                'imagen': `${BaseUrl}Modelos/Fotos/x7-5.jpg`, 'titulo': '', 'texto': '',
            },
        ],
    },
    {
        'id': 3,
        'vehiculo': 'X7',
       'banner': `${BaseUrl}Modelos/Banners/02X7.png`,
        'logo': `${BaseUrl}Modelos/Logos/X7_Negro.png`,
        'precio': "234,990.00",
       'galeria': [
            { 'src': `${BaseUrl}Modelos/Galeria/r1c1-x7.png`, 'orden': 'c1', 'columnas': 1, 'link': '', 'titulo': 'Control de espera en pendientes (HHC)', 'height': 1062 },
            { 'src': `${BaseUrl}Modelos/Galeria/r1c2-x7.png`, 'orden': 'c2', 'columnas': 1, 'link': '', 'titulo': 'Panel texturizado negro', 'height': 531 },
            { 'src': `${BaseUrl}Modelos/Galeria/r1c3-x7.png`, 'orden': 'c3', 'columnas': 1, 'link': '', 'titulo': 'Asientos ergonómicos (Incluye asiento de pasajero premium)', 'height': 531 },
            { 'src': `${BaseUrl}Modelos/Galeria/r2c1-x7.png`, 'orden': 'c1', 'columnas': 1, 'link': '', 'titulo': 'El diseño distintivo crea una estética moderna y audaz', 'height': 531 },
            { 'src': `${BaseUrl}Modelos/Galeria/r2c2c3-x7.png`, 'orden': 'c2', 'columnas': 2, 'link': '', 'titulo': 'Control de crucero adaptativo integrado (IACC)', 'height': 531 },
        
          ],
        'caracteristicas1': [
            { 'srcimg': `${BaseUrl}Modelos/Fotos/x7-1.jpg`, 'link': '', 'titulo': '', 'texto':''},
            { 'srcimg': `${BaseUrl}Modelos/Fotos/x7-2.jpg`, 'link': '', 'titulo': '', 'texto':'' },
          ],
        'caracteristicas2': [
            {
                'imagen': `${BaseUrl}Modelos/Fotos/x7-3.jpg`, 'titulo': 'Interior', 'texto': 'El diseño cuidadoso y la utilización extrema del espacio del TODO NUEVO X7 garantizan que cada centímetro del interior esté optimizado para ofrecer las soluciones de almacenamiento más funcionales y convenientes.',
            },
            {
                'imagen': `${BaseUrl}Modelos/Fotos/x7-4.jpg`, 'titulo': 'Techo corredizo panorámico', 'texto': 'Con un tamaño líder en su clase de 1320 x 850 mm, ofrece una experiencia visual inmersiva y cinematográfica.',
            },
            {
                'imagen': `${BaseUrl}Modelos/Fotos/x7-5.jpg`, 'titulo': 'Inteligencia', 'texto': 'Embárquese en un viaje hacia el futuro con las funciones de conducción inteligente de nuestro nuevo automóvil. Disfrute de la libertad que brinda la conducción inteligente, donde cada decisión y maniobra se ve mejorada por tecnología de punta.',
            },
        ],
    },
    {
        'id': 4,
        'vehiculo': 'X55',
        'banner': `${BaseUrl}Modelos/Banners/03X55.png`,
        'logo': `${BaseUrl}Modelos/Logos/X55_Negro.png`,
        'precio': "199,990.00",
        'galeria': [
            { 'src': `${BaseUrl}Modelos/Galeria/r1c1-x55.png`, 'orden': 'c1', 'columnas': 1, 'link': '', 'titulo': 'Apoyabrazos central delantero', 'height': 1062 },
            { 'src': `${BaseUrl}Modelos/Galeria/r1c2-x55.png`, 'orden': 'c2', 'columnas': 1, 'link': '', 'titulo': 'Palanca de cambios electrónica', 'height': 531 },
            { 'src': `${BaseUrl}Modelos/Galeria/r1c3-x55.png`, 'orden': 'c3', 'columnas': 1, 'link': '', 'titulo': 'Volante multifunción con ajuste de 4 posiciones', 'height': 531 },
            { 'src': `${BaseUrl}Modelos/Galeria/r2c1-x55.png`, 'orden': 'c1', 'columnas': 1, 'link': '', 'titulo': 'Asiento del conductor con ajuste manual de 6 posiciones', 'height': 531 },
            { 'src': `${BaseUrl}Modelos/Galeria/r2c2c3-x55.png`, 'orden': 'c2', 'columnas': 2, 'link': '', 'titulo': 'Pantalla de control central y Pantalla de instrumentos LCD ', 'height': 531 },
        
          ],
        'caracteristicas1': [
            { 'srcimg': `${BaseUrl}Modelos/Fotos/x55-11.jpg`, 'link': '', 'titulo': '', 'texto':''},
            { 'srcimg': `${BaseUrl}Modelos/Fotos/x55-12.jpg`, 'link': '', 'titulo': '', 'texto':'' },
          ],
        'caracteristicas2': [
            {
                'imagen': `${BaseUrl}Modelos/Fotos/x55-2.jpg`, 'titulo': 'Motor Magic Core 1.5T', 'texto': 'Desarrollado conjuntamente por BAIC y Alemania Meta con una Potencia máx. de 138 Kw y con un Torque de 305 NM, de 0 a 100 km en 7,84s',
            },
            {
                'imagen': `${BaseUrl}Modelos/Fotos/x55-3.jpg`, 'titulo': 'Diseño del cuerpo de la fortaleza espacial', 'texto': 'La rigidez de la carrocería del vehículo podría alcanzar 21872 N m/grado, fortaleciendo la protección de seguridad en la reducción de choques y deformaciones.',
            },
            {
                'imagen': `${BaseUrl}Modelos/Fotos/x55-5.jpg`, 'titulo': 'Control de crucero adaptativo inteligente', 'texto': 'La navegación inteligente se puede iniciar durante una conducción de larga distancia, lo que facilita la asistencia en atascos a baja velocidad.',
            },
        ],
    },
    {
        'id': 5,
        'vehiculo': 'X35',
        'banner': `${BaseUrl}Modelos/Banners/04X35.png`,
        'logo': `${BaseUrl}Modelos/Logos/X35_Negro.png`,
        'precio': "164,990.00",
        'galeria': [
            { 'src': `${BaseUrl}Modelos/Galeria/r1c1-x35.png`, 'orden': 'c1', 'columnas': 1, 'link': '', 'titulo': 'Costuras de grabado láser en 3D', 'height': 1062 },
            { 'src': `${BaseUrl}Modelos/Galeria/r1c2-x35.png`, 'orden': 'c2', 'columnas': 1, 'link': '', 'titulo': 'Diseño de columna de dirección colapsable.', 'height': 531 },
            { 'src': `${BaseUrl}Modelos/Galeria/r1c3-x35.png`, 'orden': 'c3', 'columnas': 1, 'link': '', 'titulo': 'Interior de tela que utiliza nanotecnología avanzada', 'height': 531 },
            { 'src': `${BaseUrl}Modelos/Galeria/r2c1-x35.png`, 'orden': 'c1', 'columnas': 1, 'link': '', 'titulo': 'Experiencia de conducción segura, agradable y de alta calidad', 'height': 531 },
            { 'src': `${BaseUrl}Modelos/Galeria/r2c2c3-x35.png`, 'orden': 'c2', 'columnas': 2, 'link': '', 'titulo': 'Pantalla de Control Central de 8 pulgadas', 'height': 531 },
        
          ],
        'caracteristicas1': [
            { 'srcimg': `${BaseUrl}Modelos/Fotos/x35-1.jpg`, 'link': '', 'titulo': '', 'texto':''},
            { 'srcimg': `${BaseUrl}Modelos/Fotos/x35-2.jpg`, 'link': '', 'titulo': '', 'texto':'' },
          ],
        'caracteristicas2': [
            {
                'imagen': `${BaseUrl}Modelos/Fotos/x35-3.jpg`, 'titulo': 'Excelente diseño', 'texto': 'El coeficiente de resistencia al viento, el gran techo corredizo panorámico, el sistema de silencio NVH estándar de clase mundial, el tratamiento de aislamiento acústico 12 del vehículo.',
            },
            {
                'imagen': `${BaseUrl}Modelos/Fotos/x35-4.jpg`, 'titulo': 'Diseño dinámico de línea lateral', 'texto': 'Techo suspendido y diseño de rueda deportivos.',
            },
            {
                'imagen': `${BaseUrl}Modelos/Fotos/x35-5.jpg`, 'titulo': 'Faros delanteros automáticos ennegrecidos Bionics Hawk-eye', 'texto': 'Luz trasera Skyline, intermitentes fluidos, luces de circulación diurna guiadas por luz de paredes gruesas en forma de L, 205 fuentes de luz LED de alta calidad',
            },
        ],
    },
]

export const correo_receptor = 'info.autosbaic@sikamotors.com';
export const correo = 'mailto:info.autosbaic@sikamotors.com';
export const llamada = 'tel:+50224202200';
export const num_llamada = '+502 2420-2200';
export const num_whatsapp = '+502 3758-1166';
export const whatsapp = 'https://wa.me/+50237581166';
export const facebook = 'https://www.facebook.com/people/BAIC-Guatemala/61569345028668/';
export const instagram = 'https://www.instagram.com/baic.gt';
