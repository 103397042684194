import { Outlet } from "react-router-dom";
import Footer from "../../pagina/Footer";
import BotonesFlotantes from "../../pagina/BotonesFloteantes";
import Menu from "../../menu/Menu";

export default function Layout(){
    return(
        <>
        <Menu />
        <Outlet />
        <Footer />
        <BotonesFlotantes />
        </>
    )
}