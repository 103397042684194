import { BaseUrl } from "../../Helpers/ConsData"
export default function FeaturesSection({imgsection}){
    return(
        <>
    {imgsection.map((item, index) => (
  <section
    key={index}
    className="page-section-xxxl mt-5 position-relative"
    style={{
      backgroundImage: `url('${item.imagen}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      height: '300px', // Ajusta la altura según necesites
    }}
  >
    {/* Contenedor para el título y el texto */}
    <div
      className="text-container"
      style={{
        position: 'absolute',
        bottom: '0',
        width: '100%',
        textAlign: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo oscuro semitransparente
        color: 'white', // Texto blanco
        padding: '20px 10px',
      }}
    >
      <h2 className=" Poppins-Regular" style={{ margin: '0 0 10px', fontSize: '1.5rem', fontWeight: 'bold' }}>
        {item.titulo}
      </h2>
      <p className=" Poppins-Regular" style={{ margin: 0, fontSize: '1rem' }}>{item.texto}</p>
    </div>
  </section>
))}


        
        </>
    )
}