import { whatsapp, facebook, instagram } from "../Helpers/ConsData";


export default function BotonesFlotantes(){
    return(
        <>
        <div className="social-media-float">
            <a href={whatsapp} target="_blank" rel="noopener noreferrer" className="icon-wt whatsapp">
                <i className="fa-brands fa-whatsapp fa-lg"></i>
            </a>
            <a href={facebook} target="_blank" rel="noopener noreferrer" className="icon facebook">
                <i className="fa-brands fa-facebook-f fa-lg"></i>
            </a>
        
            <a href={instagram} target="_blank" rel="noopener noreferrer" className="icon instagram">
                <i className="fa-brands fa-instagram fa-lg"></i>
            </a>
            <a href="#Formulario_cotizar" rel="noopener noreferrer" className="icon facebook">
                <i className="fa-brands fa-readme fa-lg"></i>
            </a>
        </div>
        </>
    )
}