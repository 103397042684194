import React, { useState } from 'react';
import { modelosoffroad, modelossuv } from '../Helpers/ConsData';
import SectionModelos2 from './SectionModelos2';

export default function SectionModelos() {
    const [selectedSlide, setSelectedSlide] = useState(0);
    const [selectedModel, setSelectedModel] = useState(modelosoffroad.modelos[0]);

    const handleModelSelect = (modelo, index) => {
        
        setSelectedModel(modelo); // Guarda el modelo seleccionado
    };

    return (
        <>
            <div className="container mt-5 Poppins-Bold ">
                <h4 className="text-center mb-3">EXPLORE YOUR BAIC</h4>
                <div className="d-flex justify-content-center mb-4">
                    <a
                        className={`mx-2 cursor-active-option h5 ${selectedSlide === 0 ? 'active-slide-option' : 'text-secondary'}`}
                        onClick={() => {
                            setSelectedSlide(0);
                            handleModelSelect(modelosoffroad.modelos[0], 0);
                          }}
                        data-target="#carouselModelos"
                        data-slide-to="0"
                    >
                        Off-road
                    </a>
                    <a
                        className={`mx-5 cursor-active-option h5 ${selectedSlide === 1 ? 'active-slide-option' : 'text-secondary'}`}
                        onClick={() => {
                            setSelectedSlide(1);
                            handleModelSelect(modelossuv.modelos[0], 0);
                          }}
                        data-target="#carouselModelos"
                        data-slide-to="1"
                    >
                        SUV
                    </a>
                </div>

                <div id="carouselModelos" className="carousel slide" data-ride="carousel" data-interval="false">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="row justify-content-center text-center">
                               
                            {modelosoffroad.modelos.map((modelo, index) => (
                            <div className="col-md-2" key={index}>
                                <a
                                    className='text-undecorated text-dark cursor-active'
                                    onClick={() => handleModelSelect(modelo, index)} // Actualiza el estado al hacer clic
                                    
                                >
                                    <img src={modelo.imgSrc} className="d-block w-100" alt={modelo.title} />
                                    <h5>{modelo.title}</h5>
                                </a>
                            </div>
                        ))}
                            </div>
                        </div>

                        <div className="carousel-item">
                            <div className="row text-center justify-content-center">
                            {modelossuv.modelos.map((modelo, index) => (
                            <div className="col-md-2" key={index}>
                                <a
                                    className='text-undecorated text-dark cursor-active'
                                    onClick={() => handleModelSelect(modelo, index)} // Actualiza el estado al hacer clic
                                    
                                >
                                    <img src={modelo.imgSrc} className="d-block w-100" alt={modelo.title} />
                                    <h5>{modelo.title}</h5>
                                </a>
                            </div>
                        ))}
                            </div>
                        </div>
                    </div>
                </div>


    

                

                 
                <SectionModelos2 colores={selectedModel.colores} />

                



            </div>

            
        </>
    );
}